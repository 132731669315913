import React, { forwardRef } from 'react'
import { Button as ButtonChakraUI } from '@chakra-ui/react'

import { get } from 'lodash'

const Button = (props, bgColor, textColor, borderColor, ref) => {
  let colors = {
    bg: bgColor,
    text: textColor,
    border: borderColor,
  }
  if (get(props, 'isDisabled')) {
    colors = {
      bg: 'gray.300',
      text: 'white',
      border: 'white',
    }
  }
  return (
    <ButtonChakraUI
      ref={ref}
      color={colors.text}
      bg={colors.bg}
      border="1px"
      borderColor={colors.border}
      _hover={{ bg: colors.border, color: colors.bg, borderColor: colors.bg }}
      fontSize={12}
      {...props}>
      {props.children}
    </ButtonChakraUI>
  )
}

const ButtonPrimary = forwardRef((props, ref) => {
  const { reverse = false, ...rest } = props
  return reverse
    ? Button({ ...rest }, 'white', 'michelinPrimary', 'michelinPrimary', ref)
    : Button({ ...rest }, 'michelinPrimary', 'white', 'white', ref)
})
ButtonPrimary.displayName = 'ButtonPrimary'

const ButtonSecondary = forwardRef((props, ref) => {
  return Button(props, 'white', '#4D4D4D', '#AF8D5A', ref)
})
ButtonSecondary.displayName = 'ButtonSecondary'

const buttonTabCommonStyle = {
  borderBottomLeftRadius: '0px',
  borderBottomRightRadius: '0px',
  fontSize: '12px !important',
  textTransform: 'capitalize',
}

const ButtonTab = forwardRef((props, ref) => {
  const { isActive = false, ...rest } = props
  return isActive
    ? Button(
        {
          ...rest,
          ...buttonTabCommonStyle,
          bottom: '-1px',
          borderBottomColor: 'white',
          _hover: { color: 'michelinPrimary' },
        },
        'white',
        'michelinPrimary',
        'gray.200',
        ref
      )
    : Button(
        {
          ...rest,
          ...buttonTabCommonStyle,
          _hover: { color: 'gray.900' },
        },
        'gray.50',
        'gray.600',
        'transparent',
        ref
      )
})
ButtonTab.displayName = 'ButtonTab'

export { ButtonPrimary, ButtonSecondary, ButtonTab }
