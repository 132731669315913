import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'
import { tHtml } from '@i18n'
import HtmlContainer from '@components/HtmlContainer'
import { writeLog } from '@api'

const StaticPage = ({ pageName, footerPage }) => {
  const { t } = useTranslation()
  const [html, setHtml] = useState('')
  const [page, setPage] = useState('default.html')

  useEffect(() => {
    console.log('[StaticPage] pageName', pageName)
    console.log('[StaticPage] footerPage', footerPage)
    try {
      if (!isEmpty(footerPage)) {
        tHtml(footerPage).then((__html) => setHtml(__html))
      } else if (!isEmpty(page)) {
        tHtml(page).then((__html) => {
          writeLog(pageName + ' ' + __html, '')
          setHtml(__html)
        })
      } else {
        writeLog(pageName + ' isEmpty', '')
      }
    } catch (error) {
      console.error('[StaticPage] footer error:', error)
    }
  }, [html, page, footerPage])

  useEffect(() => {
    console.log('[StaticPage] pageName', pageName)
    console.log('[StaticPage] footerPage', footerPage)
    if (!isEmpty(pageName)) {
      const tmp = t(pageName)
      writeLog(pageName + ' ' + tmp, '')

      if (!isEmpty(tmp)) {
        setPage(tmp)
      }
    }
  }, [pageName])

  return (
    <Box pt={10} pb={10} px={[6, 20, 20, 24, 40]}>
      {!isEmpty(html) && <HtmlContainer html={html} />}
    </Box>
  )
}
export default StaticPage
